import { Outlet } from 'react-router-dom';
import { LoginButton } from '../../business';

export function Layout() {
	return (
		<>
			<header>
				nav-bar
				<LoginButton/>
			</header>
			<main>
				<Outlet/>
			</main>
		</>
	);
}
