export function updateAble() {
	let state: {
		listeners: (() => void)[],
		callers: number
	} = { listeners: [], callers: 0 };
	return {
		update(callback: () => void): () => void {
			if (state.callers > 0) {
				state = { listeners: [...state.listeners], callers: 0 };
			}
			state.listeners.push(callback);
			return () => {
				if (state.callers > 0) {
					state = { listeners: [...state.listeners], callers: 0 };
				}
				const index = state.listeners.indexOf(callback);
				if (index > -1) {
					state.listeners.splice(index, 1);
				}
			};
		},
		triggerUpdate() {
			const currentState = state;
			currentState.callers++;
			for (const listener of currentState.listeners) {
				listener();
			}
			currentState.callers--;
		},
	};
}
