import { Button } from '../../components';
import { useAuthentication } from '../authentication/useAuthentication';

export function LoginButton() {
	const auth = useAuthentication();
	return (
		auth.state === 'logged-out' ? <Button onClick={auth.login}>Login</Button> :
		<Button onClick={auth.logout}>Logout</Button>
	);
}
