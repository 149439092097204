import { ReactNode, SyntheticEvent } from 'react';

interface Button {
	onClick: (e: SyntheticEvent) => void,
	children: ReactNode,
}

export function Button({ onClick, children }: Button) {
	return (
		<button onClick={onClick}>
			{children}
		</button>
	);
}
