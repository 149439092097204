import { LoginButton } from './Button';

export function LoginScreen() {
	return (
		<div>
			<p>Welcome to our {(window as any).application_settings.env} web app</p>
			<LoginButton/>
		</div>
	);
}
