import { useContext } from 'react';
import { AuthenticationContext } from './Context';


export function useAuthentication(): AuthenticationContext {
	const value = useContext(AuthenticationContext);
	if (value === null) throw new Error('AuthenticationContext was not found in scope');
	return value;
}

