import { RouteObject } from 'react-router-dom';
import { Home } from './Home';
import { LoginRequired } from '../business';
import { appRoutes } from './app';

export const routes: RouteObject[] = [
	{
		path: '',
		element: <Home/>,
	},
	{
		path: 'app',
		element: <LoginRequired/>,
		children: appRoutes,
	},
];
