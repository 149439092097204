import { Navigate } from 'react-router';
import { LoginScreen } from '../business';
import { useAuthentication } from '../business/authentication/useAuthentication';

export function Home() {
	const auth = useAuthentication();

	if (auth.state === 'logged-in') {
		return (
			<Navigate to={'/app'} replace />
		);
	}

	return (
		<LoginScreen/>
	);
}
