import { RouteObject } from 'react-router-dom';
import { Home } from './Home';
import { Layout } from './Layout';
import { pdcRoutes } from './pcd';

export const appRoutes: RouteObject[] = [
	{
		element: <Layout/>,
		children: [
			{
				path: '',
				element: <Home/>,
			},
			{
				path: 'pdc',
				children: pdcRoutes,
			},
		],
	},
];
