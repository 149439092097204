import { AuthenticationSettings } from './business';

declare global {
	interface Window {
		application_settings: {
			env: 'development' | 'production',
			api_url: string,
			auth: AuthenticationSettings
		}
	}
}
if (!window.application_settings) {
	throw new Error('The file public/settings.js was not loaded correctly');
}
export const ENV = window.application_settings.env;
export const AUTH = window.application_settings.auth;
export const API_URL = window.application_settings.api_url;
export const FETCH_TIMEOUT = 30 * 1000;
