import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';
import { NavigateFunction } from 'react-router-dom';
import { updateAble } from '../../util';
import unknownToError from '../../util/unknownToError';
import { AuthenticationContext } from './Context';

interface Auth0Settings {
	refreshTimer: number,
	domain: string,
	client_id: string,
	audience: string,
	scope: string
}

export class Auth0Authentication implements AuthenticationContext {
	private updateHandler = updateAble();

	token = 'Auth0Authentication';
	state: 'logged-in' | 'logged-out' = 'logged-out';
	loadPromise: Promise<void> | null;
	error: Error | null = null;
	auth0!: Auth0Client;

	constructor(private navigate: NavigateFunction, private settings: Auth0Settings) {
		this.loadPromise = (async() => {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const onRedirectCallback = (appState: {targetUrl?: string}) => {
				// history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
				if (appState.targetUrl) navigate(appState.targetUrl);
			};
			const newUrl = new URL(window.location.href);
			newUrl.hash = '';
			newUrl.search = '';
			newUrl.pathname = '/';
			this.auth0 = await createAuth0Client({
				domain: settings.domain,
				clientId: settings.client_id,
				cacheLocation: 'localstorage',
				useRefreshTokens: true,
				authorizationParams: {
					audience: settings.audience,
					scope: settings.scope,
					redirect_uri: newUrl.href,
				},
			});
			if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
				try {
					const result = await this.auth0.handleRedirectCallback();
					onRedirectCallback(result.appState);
				} catch (e) {
					// This happens when the user is slow in authenticating, or other errors happen. We don't want the app
					// to crash in this situation, so we ignore this error. It also happens if the user goes back though
					// the history, and ends up on an url that inludes a code/state
					// eslint-disable-next-line no-console
					console.error('Auth0 login error ', e);
				}
			}
			if (await this.auth0.isAuthenticated()) {
				this.token = await this.fetchToken();
				this.state = 'logged-in';
				this.updateHandler.triggerUpdate();
			} else {
				this.state = 'logged-out';
				this.updateHandler.triggerUpdate();
			}
		})().then(() => {
			this.loadPromise = null;
		}, (error) => {
			this.error = unknownToError(error);
		});
	}
	checkReady(): void {
		if (this.error) throw this.error;
		if (this.loadPromise) throw this.loadPromise;
	}

	async getUserProfile() {
		return this.auth0.getUser();
	}

	startRepeatingTasks() {
		const timer = setInterval(async () => {
			if (await this.auth0.isAuthenticated()) {
				this.token = await this.fetchToken();
				this.updateHandler.triggerUpdate();
			}
		}, this.settings.refreshTimer);
		return () => {
			clearInterval(timer);
		};
	}

	fetchToken() {
		return this.auth0.getTokenSilently();
	}

	update = (callback: () => void) => {
		return this.updateHandler.update(callback);
	};
	async login(): Promise<void> {
		this.auth0.loginWithRedirect({
			appState: {
				targetUrl: window.location.pathname + window.location.search + window.location.hash,
			},
		});
		this.updateHandler.triggerUpdate();
	}
	async logout(): Promise<void> {
		const newUrl = new URL(window.location.href);
		newUrl.hash = '';
		newUrl.search = '';
		newUrl.pathname = '/';
		this.auth0.logout({ logoutParams: {
			returnTo: newUrl.href,
		} });
		this.updateHandler.triggerUpdate();
	}

}
