import { User } from '@auth0/auth0-spa-js';
import { createContext } from 'react';


export interface AuthenticationContext {
	token: string,
	state: 'logged-in' | 'logged-out',
	loadPromise: Promise<void> | null

	getUserProfile(): Promise<User | undefined>
	startRepeatingTasks(): () => void
	update(callback: () => void): () => void;
	login(): Promise<void>;
	logout(): Promise<void>;
	checkReady(): void
}

export const AuthenticationContext = createContext<AuthenticationContext>(null as unknown as AuthenticationContext);
