import { User } from '@auth0/auth0-spa-js';
import { sleep, updateAble } from '../../util';
import unknownToError from '../../util/unknownToError';
import { AuthenticationContext } from './Context';

export class DummyAuthentication implements AuthenticationContext {
	private static readonly LOCALSTORAGE_KEY = 'DummyAuthentication';
	private updateHandler = updateAble();

	token = 'DummyAuthentication';
	state: 'logged-in' | 'logged-out' = 'logged-out';
	loadPromise: Promise<void> | null;
	error: Error | null = null;

	constructor() {
		this.loadPromise = (async() => {
			await sleep(500);
			this.state = localStorage.getItem(DummyAuthentication.LOCALSTORAGE_KEY) === 'logged-in' ? 'logged-in' : 'logged-out';
			this.updateHandler.triggerUpdate();
		})().then(() => {
			this.loadPromise = null;
		}, (error) => {
			this.error = unknownToError(error);
		});
	}

	startRepeatingTasks() {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		return () => {};
	}
	update = (callback: () => void) => {
		return this.updateHandler.update(callback);
	};
	async login(): Promise<void> {
		//this.state = 'logged-in';
		localStorage.setItem(DummyAuthentication.LOCALSTORAGE_KEY, 'logged-in');
		//this.updateHandler.triggerUpdate();
		// Mock the redirect Auth0 does
		location.reload();
	}
	async logout(): Promise<void> {
		//this.state = 'logged-out';
		localStorage.removeItem(DummyAuthentication.LOCALSTORAGE_KEY);
		//this.updateHandler.triggerUpdate();
		// Mock the redirect Auth0 does
		location.reload();
	}
	async getUserProfile(): Promise<User | undefined> {
		return Promise.resolve(this.state === 'logged-in' ? {
			email: 'DummyAuthentication@zorgverkeer.nl',
		} : undefined);
	}
	checkReady(): void {
		if (this.error) throw this.error;
		if (this.loadPromise) throw this.loadPromise;
	}
}
