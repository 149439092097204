
import { NavigateFunction } from 'react-router-dom';
import { AUTH } from '../../configuration';
import { Values } from '../../types';
import { Auth0Authentication } from './Auth0Authentication';
import { AuthenticationContext } from './Context';
import { DummyAuthentication } from './DummyAuthentication';

export * from './Context';
export * from './ContextProvider';

const availableAuthentications = {
	auth0: Auth0Authentication,
	dummy: DummyAuthentication,
};

type SecondOr<A extends any[], D> = A extends [any, infer R, ...any[]] ? R : D
export type AuthenticationSettings = Values<{
	[K in keyof typeof availableAuthentications]: {
		type: K,
	} & SecondOr<ConstructorParameters<typeof availableAuthentications[K]>, object>
}>
export class Authentication implements AuthenticationContext {
	private upstream: AuthenticationContext;

	constructor(navigate: NavigateFunction, auth: AuthenticationSettings = AUTH) {
		this.upstream = new availableAuthentications[auth.type](navigate, auth as any);
	}
	get token() {
		return this.upstream.token;
	}
	get state() {
		return this.upstream.state;
	}
	get loadPromise() {
		return this.upstream.loadPromise;
	}
	getUserProfile = () => this.upstream.getUserProfile();
	startRepeatingTasks = () => this.upstream.startRepeatingTasks();
	update = (arg: () => void) => this.upstream.update(arg);
	login = () => this.upstream.login();
	logout = () => this.upstream.logout();
	checkReady = () => this.upstream.checkReady();
}
