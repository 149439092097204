import { ReactNode } from 'react';
import { Outlet } from 'react-router';
import { reactNodeToElement } from '../../util/reactNodeToElement';
import { useAuthentication } from '../authentication/useAuthentication';
import { LoginScreen } from './LoginScreen';

interface LoginRequired {
	children?: ReactNode,
	fallback?: ReactNode,
}

const defaultChildren = <Outlet/>;
const defaultFallback = <LoginScreen/>;

export function LoginRequired({ children = defaultChildren, fallback= defaultFallback }: LoginRequired) {
	const auth = useAuthentication();

	if (auth.state === 'logged-in') {
		return reactNodeToElement(children);
	} else {
		return reactNodeToElement(fallback);
	}
}
