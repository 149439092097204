import { ReactNode } from 'react';
import { AuthenticationContext } from './Context';

interface AuthenticationContextProvider {
	value: AuthenticationContext
	children: ReactNode,
}

export function AuthenticationContextProvider({ value, children }: AuthenticationContextProvider) {
	value.checkReady();
	return (
		<AuthenticationContext.Provider value={value}>
			{children}
		</AuthenticationContext.Provider>
	);
}
