import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Authentication, AuthenticationContextProvider } from './business';
import {
	createBrowserRouter,
	RouterProvider,
} from 'react-router-dom';
import { routes } from './routes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

/**
 * For a list of all the routes inside the application, see the file `./routes/index.tsx`
 */
const router = createBrowserRouter(routes);
/**
 * This manages our request
 */
const queryClient = new QueryClient();
/**
 * This handles our authentication
 */
const authentication = new Authentication(router.navigate);

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);
root.render(
	<React.StrictMode>
		<Suspense>
			<QueryClientProvider client={queryClient}>
				<AuthenticationContextProvider value={authentication}>
					<RouterProvider router={router}/>
				</AuthenticationContextProvider>
			</QueryClientProvider>
		</Suspense>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
